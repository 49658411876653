export default {
	name: "doctor",
	data() {
		return {
			getPatient: {
				page: 1,
				size: 10,
				userType: 2,
				uuid: 'string',
				account:''
			},
			tableData: [],
			operateList: [],
			total: 0,
			//关联患者
			doctorShow: Number,
			//禁用按钮
			statusShow: Number,
		}
	},
	created() {
		this.getPatient.uuid = this.$route.query.getDoctorManageUuid;
		console.log(this.getPatient.uuid)
		this.getPatientList();
	},
	mounted() {
		sessionStorage.setItem("detail", true);
	},
	methods: {
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getPatient.size = newSize;
			this.getPatientList()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getPatient.page = newPage;
			this.getPatientList()
		},
		/*获取患者列表数据*/
		getPatientList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.$axios({
				method: "post",
				url: "/api/sys/web/user/page", // 接口地址
				data: this.getPatient
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					console.log(this.tableData )
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
	}
}
